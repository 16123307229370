import React from 'react';
// import section header
// import sections
import GenericSection from '../components/sections/GenericSection';
import Roadmap from '../components/sections/Roadmap';
// import some required elements
import Image from '../components/elements/Image';

class Secondary extends React.Component {

  state = {
    demoModalActive: false,
    videoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  openVideoModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  }

  closeVideoModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  }  

  render() {

    return (
      <React.Fragment>

        <GenericSection>
          <div className="container-xs">
            <h2 className="mt-0">Electrolysis by <a href="/">Ferekarpos U.G.</a></h2>
            <p>
            <a href="/">Ferekarpos U.G.</a> having identified the disadvantages of the various biological and mechanical treatment methods, promotes patented electrodes and technologies for water and wastewater treatment.
            </p>
            <p>
            <a href="/">The Ferekarpos U.G.</a> electrolytic treatment method belongs to the Advanced Oxidation Processes (AOPs) and its operation principle is based on the electrolysis of a brine solution.
            </p>
            <p>
            During the flow of the brine solution through the electrode, very powerful and effective oxidants are produced and act cooperatively, such as for example:
            </p>
            <ul>
              <li>Nascent Chlorine, Cl2</li>
              <li>Chlorine Dioxide, ClO2</li>
              <li>Ozone, O3</li>
              <li>Atomic Oxygen, Ο-</li>
              <li>Hydrogen Peroxide, Η2Ο2</li>
              <li>Hydroxyl Radicals, ΟΗ-</li>
              <li>Hydrogen, Η2</li>
              <li>Chlorine Radical, Cl-</li>
            </ul>
            <p>
            This way, the total oxidation of the organic waste, in general, and the destruction of all pathogenic microorganisms is achieved.
            </p>
            <p>
            The oxidant solution produced by the patented electrode does not create organ chlorine compounds THM’s , due to the presence of atomic Oxygen, Ozone and Hydrogen Peroxide within the solution.
            </p>
            <p>
            The electrolytic oxidation method of <a href="/">Ferekarpos U.G.</a> is the only method applied to large scale, which can treat the micro-pollutants such as antibiotics, medicine, hormones as:17 alpha-ethinylestradiol (EE2), beta – estradiol (E2), Diclofenac, Dioxin like PCB’s, blood, drugs, pesticides, PAH’s, etc., as it is now being imposed by the European Legislation.
            </p>

          </div>
        </GenericSection>

        <Roadmap topDivider />
      </React.Fragment>
    );
  }
}

export default Secondary;