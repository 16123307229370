import React from 'react';
import HeroFull from '../components/sections/HeroFull';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Cta from '../components/sections/Cta';
import GenericSection from '../components/sections/GenericSection';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';
import SectionHeader from '../components/sections/partials/SectionHeader';

class Home extends React.Component {
  render() {

    const genericSection02Header = {
      title: 'FAQ - Our most asked questions.'
    }

    return (
      <React.Fragment>
        <HeroFull hasBgColor invertColor className="illustration-section-01" />
        <FeaturesTiles />
        <FeaturesSplit topDivider invertMobile imageFill />
        <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data={genericSection02Header} className="center-content" />
            <Accordion>
            <AccordionItem title="What do we want to achieve?" active>
                With our process of electrolysis we want the environment of nearly everything to become environmentally friendly. We are constantly researching to create even more possibilties for applications.
              </AccordionItem>
              <AccordionItem title="How does Ferekarpos protect the environment?" active>
                Our process of electrolysis makes it possible to clean nearly everything on a biochemical base. With that the applications will reduce their waste to a minimum.
              </AccordionItem>
            </Accordion>
          </div>
        </GenericSection>
        <Cta hasBgColor invertColor split />
      </React.Fragment>
    );
  }
}

export default Home;