import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: 'Applications',
      paragraph: 'These are only some of the applications made possible with our process of electrolysis. There are even more!'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-delay="200">
                    Swimming Pools
                  </h3>
                  <p className="m-0 reveal-from-left" data-reveal-delay="400">
                    Many pools use excessive amounts of chlorine which, as well as its derivatives, are harmful for the human health.
                    <br />
                    <br />
                    Pool water that has been purified with our electrodes, is crystal clear, has no odor or taste of chlorine and does not require the use of bound chlorine.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-01 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/features-split-image-01.png')}
                    alt="Features split 01"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-right" data-reveal-delay="200">
                    Olive Oil mills
                  </h3>
                  <p className="m-0 reveal-from-right" data-reveal-delay="400">
                  Waste from olive oil mills is a huge problem, because it causes destruction of the environment and the aquifer.
                  <br />
                  <br />
                  Our method solves the environmental and social problem with the best, environmentally acceptable and techno-economically efficient manner, promoting green growth by recycling all of the waste.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-02 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/features-split-image-02.png')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-delay="200">
                    Drinking water
                  </h3>
                  <p className="m-0 reveal-from-left" data-reveal-delay="400">
                  The use of closer to surface underground water, combined with the extensive use of stronger drugs and the widespread contamination of water by the expansion of human activities, creates the need for advanced methods of water purification. 
                  <br />
                  <br />
                  The treatment with our electrodes based on the production and injection of powerful oxidants ensures the highest possible physic- and chemical quality of potable water.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-03 reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/features-split-image-03.png')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;