import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Timeline from '../elements/Timeline';
import TimelineItem from '../elements/TimelineItem';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class Roadmap extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'roadmap section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'roadmap-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Applications',
      paragraph: 'There are many possible applications of our process of electrolysis. Some of them are listed below.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <Timeline>
              <TimelineItem title="The electrolytic method, is a physicochemical 3 steps method that with a novel way meets all criteria of “Circular Economy” for reusing water, producing green energy and compost production, offering comparative advantages in relation to the biological treatment technologies.">
              Sewages Treatment Plants
              </TimelineItem>
              <TimelineItem title="The volatility of the leachate’s pollutant load and its toxicity, does not allow the treatment with biological methods. It requires advanced treatment methods (AOPs), as the patented Advanced Oxidation Process of Electrolysis by Ferekarpos U.G.">
              Landfill leachate
              </TimelineItem>
              <TimelineItem title="Our method has significant advantages compared to other treatment methods such as chlorination, the use of ultraviolet or ozone treatment, additionally offering a better grade of disinfection. Finally, with this method, there is a 90% smaller possibility for the formation of derivatives of chlorinated organic compounds, THM’s, and indeed in much lower concentrations.">
                Drinking Water
              </TimelineItem>
              <TimelineItem title="Pool water that has been purified with our electrodes, is crystal clear and has no odor or taste of chlorine and does not require the use of bound chlorine. Furthermore, it has a velvet feel on the skin and creates a pleasant feeling to the swimmer.">
                Swimming Pools
              </TimelineItem>
              <TimelineItem title="The solution includes the recovery of the residual olive oil, the polyphenols, the woody material, the production of compost and via electrolysis the destruction of the residual polyphenols, the total deodorization of the waste, their sterilization and clarification, as well as the abatement of COD and BOD5 at extremely low levels.">
              Olive oil mills
              </TimelineItem>
              <TimelineItem title="With our method, the treated water outflow is totally sterilized, colorless and odorless, free of animal antibiotics and medicine, completely harmless for the environment.">
              Slaughterhouses
              </TimelineItem>
              <TimelineItem title="The washing of the premises with a solution of the produced oxidants provides the hygienization of the unit, its deodorization and the protection of the animals from epidemics.">
              Livestock Units
              </TimelineItem>
              <TimelineItem title="The application of the patented Wet Oxidation P.C electrolysis technology, can sterilize ballast water with comparative advantages against other technologies. With this specific method, ballast water, before being discharged in the new aquatic system, passes through the electrode resulting the total destruction of organic compounds, organisms and generally any pollutant.">
              Ballast Water
              </TimelineItem>
              <TimelineItem title="The electrochemical patented method Wet Oxidation P.C., with the patented electrode, through the production of powerful oxidants, achieves the on line oxidation of the hydrocarbons, making it feasible both in small and large flows. The method ensures the decomposition of the hydrocarbons, at a level suitable for disposal in the environment, based on international standards.">
              Refineries
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </section>
    );
  }
}

Roadmap.propTypes = propTypes;
Roadmap.defaultProps = defaultProps;

export default Roadmap;